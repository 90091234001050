import React, { useEffect } from 'react';
import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import { Input, Space, Typography , Form, Row, Button as AntButton, Result, Col, Image, notification, Button} from 'antd';
import { checkTransactionStatusInhouse, getCustomerPaymentDetails, postCustomerCollection } from './utils/ApiUtils';
import { DOMAIN_MAIL, EasyOwnONStatus, FLW_PUBLIC_KEY, paymentRedirectUrl, paymentdescription, paymentslogo, paymenttitle } from './constants';


import numeral from 'numeral';

import { Riple } from 'react-loading-indicators';

import { CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
const PAYWISE_LOGO = require('./paywiselogo.png');


const { Paragraph, Text } = Typography;

const queryParameters = new URLSearchParams(window.location.search)
export default function App() {
  const [form] = Form.useForm();
  
  //const { getFieldDecorator } = form.getFieldError();


  
  const [customerDetails, setcustomerDetails] = React.useState({
    payg: "",
    serialnumber: "",
    customeraccount: "",
    amount: 0,
    customername: "",
    phonenumber: "",
    loanamount: "",
    amountpaid: "",
    depositpayment: "",
    dealname:"",
    deposit:""
  });
    
    const [payingNumber, setPayingNumber] = React.useState("");
    
    const [amountToPay, setAmountToPay] = React.useState("0");
  
    const [seeDetails, setSeeDetails] = React.useState(false);
    const [payg, setPayg] =  React.useState("");
    const [phoneringing, setPhoneRinging]= React.useState(false);

    const styles= {
      container: {
        //flex: 1,
        backgroundColor: '#F2F4F4',
        alignItems: 'center',
        justifyContent: 'center',
       height:'100%',
       width:'100%',
       
      },
      textInput:{
        backgroundColor: "white", marginBottom: 9, marginTop: 1, width:'70%', height:40
      },
      welcome: { margin: 12, fontSize: 20, fontWeight: "500", alignSelf: "center", color:"black" },
      submitApplicationButton:{
        backgroundColor:"#388E3C",
        width: '100%',
        padding: 8.5,
        borderRadius: 5,
        alignSelf:'center',
        alignItems: 'center',
        marginVertical:12,
        height:50
      },
      paymentButton:{
        backgroundColor:'yellow',
        height: 50
      },
      inputWrapper:{
        //width:'80%', 
        alignItems:'center', 
        marginTop: '1%',
        backgroundColor:'white',
        padding: '2%',
        //width:'60%'
        marginBottom:'1%',
        borderRadius:8
      },
      antdInput:{
        borderRadius:4, borderWidth:2, marginTop:-4, color:'black'
      }
    }
   
  

  //let status=, tx_ref, tr_id = undefined;
   let tx_ref = queryParameters.get("tx_ref")
   let tr_id=queryParameters.get("transaction_id")
   let status=queryParameters.get("status")
 

 
const [isLoading, setIsLoading]=React.useState(false)




///Get the Client Payment Details
const getCustomerDetails=async(data)=>{
  setIsLoading(true)
   const toPayLoad={
     payg: data!==undefined?data:payg
   }
   getCustomerPaymentDetails(toPayLoad)
   .then(response=>{
     const results=response.result.data
     const initdata=response.result;
     //alert(JSON.stringify(response))
     setIsLoading(false)
     if(response.result.code==200){
     let balance = results.remainingDebt
     let minpayment = results.pricingSchedule.minPayment
     let deposit = results.pricingSchedule.upfrontPayment
     let clientDetails ={
       payg: initdata.deviceTag,
     serialnumber: initdata.deviceTag,
     customeraccount: results.contractNumber,
     amount: 0,
     customername: results.client.profile.firstName+" "+results.client.profile.lastName,
     phonenumber: results.client.contact.mobile,
     loanamount:results.totalCost,
     amountpaid:results.totalPaid,
     dealname: results.dealName,
     deposit: results.pricingSchedule.upfrontPayment
   }
     setSeeDetails(true)
     setcustomerDetails(clientDetails)
     setPayingNumber("250"+results.client.contact.mobile)
     const amountToPay = balance < minpayment 
   ? balance 
   : results.totalPaid >= deposit 
       ? minpayment 
       : deposit;

       setAmountToPay(amountToPay)
     
     form.setFieldsValue({
       PayingNumber: "250"+results.client.contact.mobile, 
       Amount:balance < minpayment?balance:minpayment,
       CustomerNumber: results.contractNumber,
       payg:initdata.deviceTag,
     })
   }else{
     
     //setErrorMessage(true)
     // setIsLoading(false)
     // notification.error({message: 'Error', description:`Device Code ${data!==undefined?data:payg} was not found`})
   }
   }).catch(error=>{
     notification.error({message: 'Error', description:error.message})
     setIsLoading(false)
     setSeeDetails(false)
      }
   )
 }


///Processs the payment
const tr_ids = payg + Date.now().toString()
  const processPayment=(event) => {
    event.preventDefault();
   
      if (parseInt(amountToPay) <= parseInt(customerDetails.loanamount) - parseInt(customerDetails.amountpaid)) {
        const toPayLoad = {
          reference: customerDetails.amountpaid<customerDetails.deposit?customerDetails.payg +"@"+customerDetails.customeraccount:payg,
          transactionId:tr_ids,
          subscriber: "+"+payingNumber,
          amount: amountToPay,
        };
      // alert(JSON.stringify(toPayLoad))
        setSeeDetails(false);
        setPhoneRinging(true);
        postCustomerCollection(toPayLoad)
          .then(response => {
           // alert(JSON.stringify(response))
            if (response.result.status === "Success") {
              window.location.replace('/?status=success&tx_ref=' + payg + '&transaction_id=' + tr_ids);
              tx_ref = queryParameters.get("tx_ref");
              tr_id = queryParameters.get("transaction_id");
              if (queryParameters.get("status") === "success") {
                setPhoneRinging(false);
                return; // Exit recursion
              }
            }else{
              console.error('Error checking record status:', response.result.message);
                  window.location.replace('/?status=error&tx_ref=' + payg);
                  tx_ref = queryParameters.get("tx_ref");
                  tr_id = queryParameters.get("transaction_id");
                  if (queryParameters.get("status") === "error") {
                    setPhoneRinging(false);
              }
            }
          })
          .catch(error => {
            setPhoneRinging(false);
            notification.error({message: 'Error', description:error.message, duration:6000})
          });
      } else {
        notification.error({ message: 'Error', description: `You cannot pay more than the balance` });
      }
    
  }
  const onFinish = () => {
    processPayment()
  };

  const onReset = () => {
    setSeeDetails(false)
    form.resetFields();
  };

  const onTextChange=(e)=>{
  if(seeDetails===true){
    setSeeDetails(false)
  }
    const input = e.target
//alert(input.value)
   
 setPayg(input.value)
   // alert(payg)
  }
  
  

 
  // const status = queryParameters.get("status")
  // const tx_ref = queryParameters.get("tx_ref")
  // const tr_id=queryParameters.get("transaction_id")

  const clearUrlData=()=>{
    queryParameters.delete('status')
    queryParameters.delete('tx_ref')
    queryParameters.delete('transaction_id')
    //window.history.pushState(0, "", '/')
    window.location.replace('/')
  }
  const clearUrlData2=()=>{
    const immutable_payg = queryParameters.get('tx_ref')
    queryParameters.delete('status')
    queryParameters.delete('tx_ref')
    queryParameters.delete('transaction_id')
    //window.history.pushState(0, "", '/')
    window.location.replace('/?devicetag='+immutable_payg)
    //window.location.close()
  }
 useEffect(() => {
  // if(queryParameters.get("status") !==null){
  //   alert(status)
  // }
  if (queryParameters.get("deviceTag") !== null || queryParameters.get("devicetag") !== null) {
    const deviceTag = queryParameters.get("deviceTag") || queryParameters.get("devicetag");
    setPayg(deviceTag)
    getCustomerDetails(deviceTag);
}
  }, 
  
  []
  ); 

  const goBackToDetails=()=>{
    setSeeDetails(true)
    setPhoneRinging(false)
  }
  return (
    <Row justify={'center'}>
                  <Row style={{backgroundColor:'white', paddingLeft:16, paddingRight:16, width:'90%', marginTop:12, borderRadius:9}}
                   justify={'center'}>
                {EasyOwnONStatus!==true ? <Text style={{fontSize:25, fontWeight:'500', color:"#263238", marginTop:8}}>{paymenttitle}</Text>  
        :<Image width={300} src={PAYWISE_LOGO} />} 
                  {status !=null && status.toString().length>0 &&
          <Col span={24}  style={{marginTop:-60}}>

            {status.toString().startsWith("success")?
            <Result
              status="success"
             
              title= {<p style={{ fontSize: 26, fontWeight: '400' }}>Your payment was successful!</p>}
              subTitle={<Text copyable style={{ fontSize: 16, fontWeight: '500' }}>
                Transaction Reference is: {tx_ref} and transaction Id is: {tr_id}</Text>}
              extra={[
                <AntButton htmlType="button" type="primary"
                  style={{}} onClick={() => clearUrlData2()}>
                  Go back
                </AntButton>,
              ]}
            />:

<Result
    status="error"
    title="Payment Failed"
    //subTitle="Please check and modify the following information before resubmitting."
    // extra={[
    //   <Button type="primary" key="console">
    //     Go Console
    //   </Button>,
    //   <Button key="buy">Buy Again</Button>,
    // ]}
  >
    <div className="desc">
      <center>
      <Paragraph>
        <Text
          strong
          style={{
            fontSize: 16,
          }}
        >
          The payment might have failed due to the following errors:
        </Text>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> You submitted incorrect details
        or you have insufficient balance. <a onClick={()=>clearUrlData2()}>Try Again &gt;</a>
      </Paragraph>
      {/* <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> The USSD prompt to eneter pin did not come 
        <a onClick={()=>processPayment()}> Retry &gt;</a>
      </Paragraph> */}
      </center>
    </div>
  </Result>
}
          </Col>
 } 
                  
{/* Form */} 
                    <Row justify={'center'} style={{width:'100%', paddingLeft:12, paddingRight:12}}>
                      <Col span={24} >
            {seeDetails === true  ?
              <center>
                {/* <Text strong>Hello, {customerDetails.customername}</Text>
                <br/> */}
                <div class="message-box">
        <h2 style={{fontWeight:"500"}}>Hello, <span class="highlight">{customerDetails.customername}</span></h2>
        <p>Your Phone is, <span class="highlight">{customerDetails.dealname}</span></p>
        <p>You have paid <span class="highlight">RWF {numeral(parseInt(customerDetails.amountpaid)).format(',')}</span> and your balance is <span class="highlight">
        RWF {numeral(parseInt(customerDetails.loanamount)-parseInt(customerDetails.amountpaid)).format(',')}</span></p>
    </div><br/>
                {/* <Text strong>Your Phone is, {customerDetails.dealname}</Text>
                
                <Text strong> You have paid RWF {numeral(parseInt(customerDetails.amountpaid)).format(',')} and your balance is 
               RWF {numeral(parseInt(customerDetails.loanamount)-parseInt(customerDetails.amountpaid)).format(',')}</Text> */}
              </center>
              : null}    
            <Form layout='vertical' form={form} name="control-hooks" onFinish={onFinish}
            
            //style={{ width:'100%'}}
            >
            {phoneringing && !seeDetails ?
           <center>
           
            <div style={{marginBottom:22}}>
              <p style={{fontSize: 25, fontWeight:'400'}}>Payment Authorisation </p>
           <Riple color="#32cd32" size="large" text="" textColor="" /><br></br>
           <Button onClick={()=>goBackToDetails()}>Go Back</Button>
           </div>
           </center>:null}
           {/* {!phoneringing && status ==null?
           <div>
           <Typography type="danger" style={{marginLeft: 6,fontWeight:'500', color:'red', fontSize:17}}>Pay with AIRTEL only</Typography>
           </div>:null} */}
              {!phoneringing&& status ==null ?  (
              //   EasyOwnONStatus ?
              //   (
              //     <center>
              //       <Space style={{paddingBottom:20, display:'block'}}>
              //       <Text strong>Dear Customer,</Text>
                   
              //       <Text strong copyable> Please Call 0705152137 for assistance</Text>
              //       </Space>
              //     </center>
              //   ):

              //   <Form.Item name="payg" label="PAYG NUMBER" rules={[{ required: true }]}>
              //   <Input style={styles.antdInput} value={payg} name='payg' 
              //    onInput={(e)=>onTextChange(e)}
              //   />
              // </Form.Item>
              
              // <Form.Item name="payg" label="PAYG NUMBER OR CONTRACT" rules={[{ required: true }]}>
              //   <Input style={styles.antdInput} value={payg} name='payg' 
              //    onInput={(e)=>onTextChange(e)}
              //   />
              // </Form.Item>

              <></>
              ):null
              }
              {seeDetails ===true && customerDetails.customeraccount!==""?
              <>
              <Form.Item name="CustomerNumber" label="Customer Number" rules={[{ required: false }]} >
                <Input size='large' style={styles.antdInput} 
                value={customerDetails.customeraccount!=="" && customerDetails.customeraccount} status={customerDetails.customeraccount!==""?'success':'error'} disabled />
              </Form.Item>
              <Form.Item name="PayingNumber" label="Paying Number" rules={[{ required: true }]}>
                <Input size='large' style={styles.antdInput} value={payingNumber.toString()} type='text'
                //onChange={(e) => setPayingNumber(e.target.value)}
                status={payingNumber!==""?'success':'error'}
                onChange={(e) => setPayingNumber(e.target.value)}
                />
              </Form.Item>
              <Form.Item name="Amount" label="Amount" rules={[{ required: true }]}>
                <Input size='large' style={styles.antdInput} onChange={(e) => setAmountToPay(e.target.value)} 
                value={amountToPay.toString()} 
                />
              </Form.Item>
              <center>
                <Form.Item >
                  <Space>
                    <AntButton type="primary" htmlType="submit"
                      loading={isLoading}
                      disabled={isLoading ? true : false}
                       onClick={(e) => processPayment(e)}
                      style={{ backgroundColor: isLoading ? '#AED6F1' : '#2471A3', color: 'white' }}
                    >
                      PAY NOW
                    </AntButton>
                    
                    <AntButton htmlType="button" onClick={onReset}>
                      Reset
                    </AntButton>
                  </Space>
                </Form.Item>
                
             
              </center>
              </>:null }
              {/* {!seeDetails &&  status ==null  && ! EasyOwnONStatus ? */}
              {!seeDetails && status == null && !phoneringing ?
                <center>
                  <Form.Item >
                    <Space>
                      <AntButton type="primary"
                        loading={isLoading}
                        disabled={isLoading ? true : false}
                        value={payg}
                        onClick={() => getCustomerDetails()}
                        style={{ backgroundColor: isLoading ? '#AED6F1' : '#2471A3', color: 'white' }}
                      >
                        REQUEST DETAILS
                      </AntButton>
                    </Space>
                  </Form.Item>
                </center> : null}
            </Form>
          </Col>
        </Row>
      </Row>
    </Row>
  );
}
